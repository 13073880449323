import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
  conversationByIdListReducer,
  conversationGetAllReducer,
  sendImageFromChatReducer,
  sendMessageFromChatByEmailReducer,
  removeChatReducer,
} from './redux/reducers/chatReducers';
import {
  handleIncomingNotificationReducer,
  handleNewFallbackReceiveNotificationReducer,
} from 'redux/reducers/notificationReducers';
import {
  GetTokenFromFCMReducer,
  DeleteTokenFromFCMReducer,
} from 'redux/reducers/tokenReducers';
import {
  usersAllListReducer,
  adminUserCreateReducer,
  getLoggedInUserDetailsReducer,
  editAdminUserReducer,
  changePasswordReducer,
  deleteUserReducer,
} from 'redux/reducers/usersReducers';
import {
  getBarChartDataReducer,
  getLineChartDataReducer,
  getPieChartDataReducer,
  getHighestProductReducer,
} from 'redux/reducers/dashboardReducers';
import { updateNotificationByIDReducer } from 'redux/reducers/notificationReducers';

import { getSynctimeReducer } from 'redux/reducers/syncReducers';

const reducer = combineReducers({
  conversationByIdList: conversationByIdListReducer,
  conversationGetAll: conversationGetAllReducer,
  sendMessageFromChatByEmail: sendMessageFromChatByEmailReducer,

  sendImageFromChat: sendImageFromChatReducer,
  removeChat: removeChatReducer,

  handleIncomingNotification: handleIncomingNotificationReducer,
  handleNewFallbackReceiveNotification:
    handleNewFallbackReceiveNotificationReducer,
  updateNotificationByID: updateNotificationByIDReducer,

  getPieChartData: getPieChartDataReducer,
  getLineChartData: getLineChartDataReducer,
  getBarChartData: getBarChartDataReducer,
  getHighestProduct: getHighestProductReducer,

  GetTokenFromFCM: GetTokenFromFCMReducer,
  DeleteTokenFromFCM: DeleteTokenFromFCMReducer,

  usersAllList: usersAllListReducer,
  adminUserCreate: adminUserCreateReducer,
  getLoggedInUserDetails: getLoggedInUserDetailsReducer,
  editAdminUser: editAdminUserReducer,
  changePassword: changePasswordReducer,
  deleteUser: deleteUserReducer,

  getSynctime: getSynctimeReducer,
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
