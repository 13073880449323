import { useDispatch } from 'react-redux';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { handleIncomingNotificationAction } from 'redux/actions/notificationActions';

let messaging = null;
let firebaseConfig = {};
let publicKey = '';

if (process.env.REACT_APP_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyDUP-BB_Eeye1w9UHMOLZL2ff9d9V0XcE0',
    authDomain: 'spa-ceylon-chatbot-production.firebaseapp.com',
    projectId: 'spa-ceylon-chatbot-production',
    storageBucket: 'spa-ceylon-chatbot-production.appspot.com',
    messagingSenderId: '384052176148',
    appId: '1:384052176148:web:088b7e10b83b9602f04e01',
    measurementId: 'G-V8ST32EE5Z',
  };
  publicKey =
    'BEBHdqK9TEEe2irnVb0zBu8ORnjoyUGKx7mAL8n9fZ0Qmt1VsgzzRIASnDRyaBdTUAdLxgT3Mboq8tv9oR6lUFc';
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyBVN6B3EImshWMT72xFCCKpQhaETk8Zfe4',
    authDomain: 'spa-ceylon-f383b.firebaseapp.com',
    databaseURL: 'https://spa-ceylon-f383b-default-rtdb.firebaseio.com',
    projectId: 'spa-ceylon-f383b',
    storageBucket: 'spa-ceylon-f383b.appspot.com',
    messagingSenderId: '123726450445',
    appId: '1:123726450445:web:01a1d3f65f1935a9ffaa44',
    measurementId: 'G-ZQNWB8SJ9D',
  };
  publicKey =
    'BMtoWhe5wxIj7HTwKwCcOeTYkUaedePFYlwhO1RzSOOirPse3_L8-wtvJBjD5NrvNYwhSWiD03mb0c41SoLj_8Q';
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const firebaseCloudMessaging = {
  init: async function () {
    if (getApps().length !== 0) {
      try {
        messaging = getMessaging();
        const status = await Notification.requestPermission();
        if (status && status === 'granted') {
          console.log('Notification permission granted.');
          if (process.env.REACT_APP_ENV === 'production') {
            getToken(messaging, {
              vapidKey: publicKey,
            })
              .then((currentToken) => {
                if (currentToken) {
                  receiveMessage();
                  localStorage.setItem('pushToken', currentToken);
                  return currentToken;
                } else {
                  console.log(
                    'No registration token available. Request permission to generate one.',
                  );
                }
              })
              .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
              });
          } else {
            const token = await getToken(messaging, { vapidKey: publicKey });
            if (token) {
              receiveMessage();
              return token;
            }
            return false;
          }
        } else {
          console.log('Unable to get permission to notify.');
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    } else {
      initializeApp(firebaseConfig);
    }
  },
};

function receiveMessage() {
  onMessage((payload) => {
    const dispatch = useDispatch();
    dispatch(handleIncomingNotificationAction(payload));
  });
}

export const auth = getAuth(app);
export const storage = getStorage(app);

export { receiveMessage, messaging, firebaseCloudMessaging };
