import {
  USERS_ALL_LIST_REQUEST,
  USERS_ALL_LIST_SUCCESS,
  USERS_ALL_LIST_FAIL,
  CREATE_ADMIN_USER_REQUEST,
  CREATE_ADMIN_USER_SUCCESS,
  CREATE_ADMIN_USER_FAIL,
  GET_LOGGED_USER_REQUEST,
  GET_LOGGED_USER_FAIL,
  GET_LOGGED_USER_SUCCESS,
  EDIT_USER_FAIL,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_RESET,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_RESET,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_RESET,
} from './../constants/usersConstants';

export const usersAllListReducer = (
  state = { userAllListDetails: [] },
  action,
) => {
  switch (action.type) {
    case USERS_ALL_LIST_REQUEST:
      return { loading: true, userAllListDetails: [] };
    case USERS_ALL_LIST_SUCCESS:
      return { loading: false, userAllListDetails: action.payload };
    case USERS_ALL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getLoggedInUserDetailsReducer = (
  state = { loggedUser: [] },
  action,
) => {
  switch (action.type) {
    case GET_LOGGED_USER_REQUEST:
      return { loading: true, loggedUser: [] };
    case GET_LOGGED_USER_SUCCESS:
      return { loading: false, loggedUser: action.payload };
    case GET_LOGGED_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminUserCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ADMIN_USER_REQUEST:
      return { loading: true };
    case CREATE_ADMIN_USER_SUCCESS:
      return { loading: false, success: true, adminUser: action.payload };
    case CREATE_ADMIN_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const editAdminUserReducer = (
  state = { adminUserUpdate: {} },
  action,
) => {
  switch (action.type) {
    case EDIT_USER_REQUEST:
      return { loading: true };
    case EDIT_USER_SUCCESS:
      return { loading: false, success: true, adminUserUpdate: action.payload };
    case EDIT_USER_FAIL:
      return { loading: false, error: action.payload };
    case EDIT_USER_RESET:
      return {
        adminUserUpdate: {},
      };
    default:
      return state;
  }
};

export const changePasswordReducer = (
  state = { changePasswordUpdate: {} },
  action,
) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return { loading: true };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        loading: false,
        success: true,
        changePasswordUpdate: action.payload,
      };
    case CHANGE_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case CHANGE_PASSWORD_RESET:
      return {
        changePasswordUpdate: {},
      };
    default:
      return state;
  }
};

export const deleteUserReducer = (state = { deleteUserUpdate: {} }, action) => {
  switch (action.type) {
    case DELETE_USER_REQUEST:
      return { loading: true };
    case DELETE_USER_SUCCESS:
      return {
        loading: false,
        success: true,
        deleteUserUpdate: action.payload,
      };
    case DELETE_USER_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_USER_RESET:
      return {
        deleteUserUpdate: {},
      };
    default:
      return state;
  }
};
