import React from 'react';
import SpaCeylonLogo from './../../assets/img/Spa-Ceylon-Logo.svg';

const index = () => {
  return (
    <div className="w-screen h-screen bg-white">
      <div className="flex justify-center items-center mx-auto ml-auto w-screen h-screen">
        <img src={SpaCeylonLogo} alt="Spa Ceylon Logo" width="250px" />
      </div>
    </div>
  );
};

export default index;
