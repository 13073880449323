const stageEnvironment = false;
const stageUrl = 'https://staging-api.spa-ceylon.rootcode.software';
const developmentUrl = 'https://dev-api.spa-ceylon.rootcode.software';
const productionUrl = 'https://api-production.spaceylonstore.com';

const env = process.env.REACT_APP_ENV;
let targetAPIUrl = '';
if (env === 'development') {
  targetAPIUrl = developmentUrl;
} else if (env === 'staging') {
  targetAPIUrl = stageUrl;
} else if (env === 'production') {
  targetAPIUrl = productionUrl;
}

export const SPA_CEYLON_LIVE_API_URL = targetAPIUrl;

const username = 'AITEAM@ROOTCODELABS';
const password = 'DKFsvdwe2JHU294894FJMVE2342[]}{]EKG$T04U8T03T48CSPR3OB4';

const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

export const API_CREDENTIALS = {
  headers: {
    Authorization: `Basic ${token}`,
  },
};
