import axios from 'axios';
import { SPA_CEYLON_LIVE_API_URL, API_CREDENTIALS } from 'services/api-url';
import {
  PREFIX_CHAT_MESSAGE,
  PREFIX_ALL_CHAT_MESSAGE,
  PREFIX_CHAT_BY_ID,
} from 'services/api-prefixes';
import { PREFIX_CHAT_IMAGE } from 'services/api-prefixes';
import { PREFIX_DELETE_HOP } from 'services/api-prefixes';

export const sendMessageService = (information) => {
  return axios.post(
    `${SPA_CEYLON_LIVE_API_URL}/${PREFIX_CHAT_MESSAGE}`,
    information,
    API_CREDENTIALS,
  );
};

export const getAllChatsService = () => {
  return axios.get(
    `${SPA_CEYLON_LIVE_API_URL}/${PREFIX_ALL_CHAT_MESSAGE}`,
    API_CREDENTIALS,
  );
};

export const getChatByIDService = (information) => {
  const pushToken = localStorage.getItem('pushToken');
  return axios.get(
    `${SPA_CEYLON_LIVE_API_URL}/${PREFIX_CHAT_BY_ID}?conversation_id=${information}&admin_push_token=${pushToken}`,
    API_CREDENTIALS,
  );
};

export const sendImageService = (information) => {
  return axios.post(
    `${SPA_CEYLON_LIVE_API_URL}/${PREFIX_CHAT_IMAGE}?user_phone=${information.user_phone}&admin_name=${information.user_name}`,
    information.formData,
    API_CREDENTIALS,
  );
};

export const removeChatService = (information) => {
  return axios.post(
    `${SPA_CEYLON_LIVE_API_URL}/${PREFIX_DELETE_HOP}`,
    information,
    API_CREDENTIALS,
  );
};
