export const USERS_ALL_LIST_REQUEST = 'USERS_ALL_LIST_REQUEST';
export const USERS_ALL_LIST_SUCCESS = 'USERS_ALL_LIST_SUCCESS';
export const USERS_ALL_LIST_FAIL = 'USERS_ALL_LIST_FAIL';

export const CREATE_ADMIN_USER_REQUEST = 'CREATE_ADMIN_USER_REQUEST';
export const CREATE_ADMIN_USER_SUCCESS = 'CREATE_ADMIN_USER_SUCCESS';
export const CREATE_ADMIN_USER_FAIL = 'CREATE_ADMIN_USER_FAIL';

export const GET_LOGGED_USER_REQUEST = 'GET_LOGGED_USER_REQUEST';
export const GET_LOGGED_USER_SUCCESS = 'GET_LOGGED_USER_SUCCESS';
export const GET_LOGGED_USER_FAIL = 'GET_LOGGED_USER_FAIL';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';
export const EDIT_USER_RESET = 'EDIT_USER_RESET';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export const DELETE_USER_RESET = 'DELETE_USER_RESET';
