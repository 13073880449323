import React, { Suspense, lazy } from 'react';
import { useAuth } from './contexts/AuthContext';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import SuspenseLoader from './components/SuspenseLoader';

const Admin = lazy(() => import('./layouts/admin.routes'));
const Login = lazy(() => import('./views/auth/Login.js'));
const ForgotPassword = lazy(() => import('./views/auth/ForgotPassword.js'));
const ResetPassword = lazy(() => import('./views/auth/ResetPassword.js'));

export default function Routes() {
  return (
    <>
      <Router>
        <Suspense fallback={<SuspenseLoader />}>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/reset-password" exact component={ResetPassword} />
            <ProtectedRoute path="/admin" component={Admin} />
            {/* add redirect for first page */}
            <Route exact path="*" render={() => <Redirect to="/" />} />
          </Switch>
        </Suspense>
      </Router>
    </>
  );
}

function ProtectedRoute(props) {
  const { currentUser } = useAuth();
  const { path } = props;
  const location = useLocation();

  if (
    path === '/' ||
    path === '/forgot-password' ||
    path === '/reset-password'
  ) {
    return currentUser ? (
      <Redirect to={location?.state?.from ?? '/admin'} />
    ) : (
      <Route {...props} />
    );
  }
  return currentUser ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: '/',
        state: { from: path },
      }}
    />
  );
}
