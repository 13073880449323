export const USER_CONVERSATION_BY_ID_REQUEST =
  'USER_CONVERSATION_BY_ID_REQUEST';
export const USER_CONVERSATION_BY_ID_SUCCESS =
  'USER_CONVERSATION_BY_ID_SUCCESS';
export const USER_CONVERSATION_BY_ID_SAVED = 'USER_CONVERSATION_BY_ID_SAVED';
export const USER_CONVERSATION_BY_ID_FAIL = 'USER_CONVERSATION_BY_ID_FAIL';
export const USER_CONVERSATION_BY_ID_RESET = 'USER_CONVERSATION_BY_ID_RESET';

export const SEND_MESSAGE_BY_EMAIL_REQUEST = 'SEND_MESSAGE_BY_EMAIL_REQUEST';
export const SEND_MESSAGE_BY_EMAIL_SUCCESS = 'SEND_MESSAGE_BY_EMAIL_SUCCESS';
export const SEND_MESSAGE_BY_EMAIL_SAVED = 'SEND_MESSAGE_BY_EMAIL_SAVED';
export const SEND_MESSAGE_BY_EMAIL_FAIL = 'SEND_MESSAGE_BY_EMAIL_FAIL';

export const GET_ALL_CONVERSATIONS_REQUEST = 'GET_ALL_CONVERSATIONS_REQUEST';
export const GET_ALL_CONVERSATIONS_SUCCESS = 'GET_ALL_CONVERSATIONS_SUCCESS';
export const GET_ALL_CONVERSATIONS_FAIL = 'GET_ALL_CONVERSATIONS_FAIL';

export const SEND_IMAGE_REQUEST = 'SEND_IMAGE_REQUEST';
export const SEND_IMAGE_SUCCESS = 'SEND_IMAGE_SUCCESS';
export const SEND_IMAGE_FAIL = 'SEND_IMAGE_FAIL';

export const REMOVE_CHAT_REQUEST = 'REMOVE_CHAT_REQUEST';
export const REMOVE_CHAT_SUCCESS = 'REMOVE_CHAT_SUCCESS';
export const REMOVE_CHAT_FAIL = 'REMOVE_CHAT_FAIL';
