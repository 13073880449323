import {
  GET_SYNCTIME_SUCCESS,
  GET_SYNCTIME_REQUEST,
  GET_SYNCTIME_FAIL,
} from 'redux/constants/syncConstants';

export const getSynctimeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SYNCTIME_REQUEST:
      return { loading: true };
    case GET_SYNCTIME_SUCCESS:
      return { loading: false, success: true, time: action.payload };
    case GET_SYNCTIME_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
