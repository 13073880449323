import {
  GET_BAR_CHART_DETAILS_REQUEST,
  GET_BAR_CHART_DETAILS_SUCCESS,
  GET_BAR_CHART_DETAILS_FAIL,
  GET_LINE_CHART_DETAILS_FAIL,
  GET_LINE_CHART_DETAILS_SUCCESS,
  GET_LINE_CHART_DETAILS_REQUEST,
  GET_PIE_CHART_DETAILS_FAIL,
  GET_PIE_CHART_DETAILS_REQUEST,
  GET_PIE_CHART_DETAILS_SUCCESS,
  GET_HIGHEST_PRODUCTS_DETAILS_REQUEST,
  GET_HIGHEST_PRODUCTS_DETAILS_SUCCESS,
  GET_HIGHEST_PRODUCTS_DETAILS_FAIL,
} from './../constants/dashboardConstants';

export const getBarChartDataReducer = (
  state = { barChartData: [] },
  action,
) => {
  switch (action.type) {
    case GET_BAR_CHART_DETAILS_REQUEST:
      return { loading: true, barChartData: [] };
    case GET_BAR_CHART_DETAILS_SUCCESS:
      return { loading: false, barChartData: action.payload };
    case GET_BAR_CHART_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getLineChartDataReducer = (
  state = { lineChartData: [] },
  action,
) => {
  switch (action.type) {
    case GET_LINE_CHART_DETAILS_REQUEST:
      return { loading: true, lineChartData: [] };
    case GET_LINE_CHART_DETAILS_SUCCESS:
      return { loading: false, lineChartData: action.payload };
    case GET_LINE_CHART_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getPieChartDataReducer = (
  state = { pieChartData: [] },
  action,
) => {
  switch (action.type) {
    case GET_PIE_CHART_DETAILS_REQUEST:
      return { loading: true, pieChartData: [] };
    case GET_PIE_CHART_DETAILS_SUCCESS:
      return { loading: false, pieChartData: action.payload };
    case GET_PIE_CHART_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getHighestProductReducer = (
  state = { highestProduct: [] },
  action,
) => {
  switch (action.type) {
    case GET_HIGHEST_PRODUCTS_DETAILS_REQUEST:
      return { loading: true, highestProduct: [] };
    case GET_HIGHEST_PRODUCTS_DETAILS_SUCCESS:
      return { loading: false, highestProduct: action.payload };
    case GET_HIGHEST_PRODUCTS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
