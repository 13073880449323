import axios from 'axios';
import {
  PREFIX_GET_NOTIFICATIONS,
  PREFIX_UPDATE_TO_READ,
} from 'services/api-prefixes';
import { SPA_CEYLON_LIVE_API_URL, API_CREDENTIALS } from 'services/api-url';

export const updateNotificationService = (information) => {
  return axios.post(
    `${SPA_CEYLON_LIVE_API_URL}/${PREFIX_UPDATE_TO_READ}`,
    information,
    API_CREDENTIALS,
  );
};

export const getAllNotifications = () => {
  return axios.get(
    `${SPA_CEYLON_LIVE_API_URL}/${PREFIX_GET_NOTIFICATIONS}`,
    API_CREDENTIALS,
  );
};
