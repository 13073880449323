export const GET_PIE_CHART_DETAILS_REQUEST = 'GET_PIE_CHART_DETAILS_REQUEST';
export const GET_PIE_CHART_DETAILS_SUCCESS = 'GET_PIE_CHART_DETAILS_SUCCESS';
export const GET_PIE_CHART_DETAILS_FAIL = 'GET_PIE_CHART_DETAILS_FAIL';

export const GET_LINE_CHART_DETAILS_REQUEST = 'GET_LINE_CHART_DETAILS_REQUEST';
export const GET_LINE_CHART_DETAILS_SUCCESS = 'GET_LINE_CHART_DETAILS_SUCCESS';
export const GET_LINE_CHART_DETAILS_FAIL = 'GET_LINE_CHART_DETAILS_FAIL';

export const GET_BAR_CHART_DETAILS_REQUEST = 'GET_BAR_CHART_DETAILS_REQUEST';
export const GET_BAR_CHART_DETAILS_SUCCESS = 'GET_BAR_CHART_DETAILS_SUCCESS';
export const GET_BAR_CHART_DETAILS_FAIL = 'GET_BAR_CHART_DETAILS_FAIL';

export const GET_HIGHEST_PRODUCTS_DETAILS_REQUEST =
  'GET_HIGHEST_PRODUCTS_DETAILS_REQUEST';
export const GET_HIGHEST_PRODUCTS_DETAILS_SUCCESS =
  'GET_HIGHEST_PRODUCTS_DETAILS_SUCCESS';
export const GET_HIGHEST_PRODUCTS_DETAILS_FAIL =
  'GET_HIGHEST_PRODUCTS_DETAILS_FAIL';
