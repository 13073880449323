import { GET_NOTIFICATIONS_RESET } from 'redux/constants/notificationConstants';
import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_FAIL,
  GET_FALLBACK_NOTIFICATIONS_REQUEST,
  GET_FALLBACK_NOTIFICATIONS_SUCCESS,
  GET_FALLBACK_NOTIFICATIONS_FAIL,
  UPDATE_NOTIFICATION_TO_READ_SUCCESS,
  UPDATE_NOTIFICATION_TO_READ_REQUEST,
  UPDATE_NOTIFICATION_TO_READ_FAIL,
} from 'redux/constants/notificationConstants';

export const handleIncomingNotificationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return { loading: true };
    case GET_NOTIFICATIONS_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case GET_NOTIFICATIONS_FAIL:
      return { loading: false, error: action.payload };
    case GET_NOTIFICATIONS_RESET:
      return {};
    default:
      return state;
  }
};

export const updateNotificationByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATION_TO_READ_REQUEST:
      return { loading: true };
    case UPDATE_NOTIFICATION_TO_READ_SUCCESS:
      return { loading: false, success: true, update_status: action.payload };
    case UPDATE_NOTIFICATION_TO_READ_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const handleNewFallbackReceiveNotificationReducer = (
  state = { notifications: [] },
  action,
) => {
  switch (action.type) {
    case GET_FALLBACK_NOTIFICATIONS_REQUEST:
      return { loading: true };
    case GET_FALLBACK_NOTIFICATIONS_SUCCESS:
      return { loading: false, success: true, notifications: action.payload };
    case GET_FALLBACK_NOTIFICATIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
