import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { handleIncomingNotificationAction } from 'redux/actions/notificationActions';
import { firebaseCloudMessaging } from 'firebase/init-firebase';
import Routes from './Routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const firebasePath =
      process.env.REACT_APP_ENV === 'production'
        ? '../prod.firebase-messaging-sw.js'
        : '../dev.firebase-messaging-sw.js';
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register(firebasePath)
        .then(function (registration) {
          console.log('Registration successful, scope is:', registration.scope);
          navigator.serviceWorker.addEventListener(
            'message',
            function handleMessageFromWorker(msg) {
              dispatch(handleIncomingNotificationAction(msg));
            },
          );
        })
        .catch(function (err) {
          console.log('Service worker registration failed, error:', err);
        });
    }

    firebaseCloudMessaging.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes />
    </div>
  );
}

export default App;
