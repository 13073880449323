import {
  GET_PUSH_TOKEN_REQUEST,
  GET_PUSH_TOKEN_SUCCESS,
  GET_PUSH_TOKEN_FAIL,
  DELETE_PUSH_TOKEN_REQUEST,
  DELETE_PUSH_TOKEN_SUCCESS,
  DELETE_PUSH_TOKEN_FAIL,
} from 'redux/constants/tokenConstants';

export const GetTokenFromFCMReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PUSH_TOKEN_REQUEST:
      return { loading: true };
    case GET_PUSH_TOKEN_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case GET_PUSH_TOKEN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const DeleteTokenFromFCMReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PUSH_TOKEN_REQUEST:
      return { loading: true };
    case DELETE_PUSH_TOKEN_SUCCESS:
      return { loading: false, success: true };
    case DELETE_PUSH_TOKEN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
