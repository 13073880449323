export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';
export const GET_NOTIFICATIONS_RESET = 'GET_NOTIFICATION_RESET';

export const GET_FALLBACK_NOTIFICATIONS_REQUEST =
  'GET_FALLBACK_NOTIFICATIONS_REQUEST';
export const GET_FALLBACK_NOTIFICATIONS_SUCCESS =
  'GET_FALLBACK_NOTIFICATIONS_SUCCESS';
export const GET_FALLBACK_NOTIFICATIONS_FAIL =
  'GET_FALLBACK_NOTIFICATIONS_FAIL';

export const UPDATE_NOTIFICATION_TO_READ_REQUEST =
  'UPDATE_NOTIFICATION_TO_READ_REQUEST';
export const UPDATE_NOTIFICATION_TO_READ_SUCCESS =
  'UPDATE_NOTIFICATION_TO_READ_SUCCESS';
export const UPDATE_NOTIFICATION_TO_READ_FAIL =
  'UPDATE_NOTIFICATION_TO_READ_FAIL';
