import {
  USER_CONVERSATION_BY_ID_REQUEST,
  USER_CONVERSATION_BY_ID_FAIL,
  USER_CONVERSATION_BY_ID_SUCCESS,
  USER_CONVERSATION_BY_ID_RESET,
  SEND_MESSAGE_BY_EMAIL_REQUEST,
  SEND_MESSAGE_BY_EMAIL_SUCCESS,
  SEND_MESSAGE_BY_EMAIL_FAIL,
  GET_ALL_CONVERSATIONS_REQUEST,
  GET_ALL_CONVERSATIONS_SUCCESS,
  GET_ALL_CONVERSATIONS_FAIL,
  SEND_IMAGE_REQUEST,
  SEND_IMAGE_SUCCESS,
  SEND_IMAGE_FAIL,
  REMOVE_CHAT_REQUEST,
  REMOVE_CHAT_SUCCESS,
  REMOVE_CHAT_FAIL,
} from './../constants/chatConstants';

export const conversationGetAllReducer = (
  state = { allConversations: [] },
  action,
) => {
  switch (action.type) {
    case GET_ALL_CONVERSATIONS_REQUEST:
      return { loading: true, allConversations: [] };
    case GET_ALL_CONVERSATIONS_SUCCESS:
      return {
        loading: false,
        allConversations: action.payload,
        success: true,
      };
    case GET_ALL_CONVERSATIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const conversationByIdListReducer = (
  state = { chatConversationList: [] },
  action,
) => {
  switch (action.type) {
    case USER_CONVERSATION_BY_ID_REQUEST:
      return { loading: true, chatConversationList: [] };
    case USER_CONVERSATION_BY_ID_SUCCESS:
      return {
        loading: false,
        chatConversationList: action.payload,
        chatIdInfo: action.getID,
      };
    case USER_CONVERSATION_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case USER_CONVERSATION_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const sendMessageFromChatByEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_MESSAGE_BY_EMAIL_REQUEST:
      return { loading: true };
    case SEND_MESSAGE_BY_EMAIL_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case SEND_MESSAGE_BY_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sendImageFromChatReducer = (
  state = { loading: false, success: false },
  action,
) => {
  switch (action.type) {
    case SEND_IMAGE_REQUEST:
      return { loading: true, success: false };
    case SEND_IMAGE_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case SEND_IMAGE_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};

export const removeChatReducer = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_CHAT_REQUEST:
      return { loading: true, success: false };
    case REMOVE_CHAT_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case REMOVE_CHAT_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};
