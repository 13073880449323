// Chat messages
export const PREFIX_CHAT_MESSAGE = 'respond-admin';
export const PREFIX_ALL_CHAT_MESSAGE = 'get-user-categories';
export const PREFIX_CHAT_BY_ID = 'get-user-conversation';
export const PREFIX_CHAT_IMAGE = 'handle-admin-image';
export const PREFIX_DELETE_HOP = 'delete-hop';

// Dashboard Statistics
export const PREFIX_BAR_CHART = 'get-bar-chart-stat';
export const PREFIX_LINE_CHART = 'get-line-chart-stat';
export const PREFIX_PIE_CHART = 'get-pie-chart-stat';
export const PREFIX_HIGHEST_PRODUCT = 'get-product-stat-table';

// FCM Tokens
export const PREFIX_PUSH_TOKEN = 'push_token';
export const PREFIX_DELETE_TOKEN = 'delete_token';
export const PREFIX_DELETE_ADMIN_CONNECTION =
  'delete-admin-view-conversation-connection';

// Notifications Handling
export const PREFIX_GET_NOTIFICATIONS = 'get_notifications';
export const PREFIX_UPDATE_TO_READ = 'read_notification';

// User Management
export const PREFIX_GET_USERS = 'get-all-users';
export const PREFIX_ADD_USER = 'create-new-user';
export const PREFIX_GET_USER_BY_ID = 'get-user-by-id';
export const PREFIX_EDIT_USER = 'edit-user-info';
export const PREFIX_CHANGE_PASSWORD = 'edit-user-password';
export const PREFIX_DELETE_USER = 'delete-user';

// Sync Data
export const PREFIX_SYNC_TIME = 'get-product-sync';
