import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_FAIL,
  GET_FALLBACK_NOTIFICATIONS_REQUEST,
  GET_FALLBACK_NOTIFICATIONS_SUCCESS,
  GET_FALLBACK_NOTIFICATIONS_FAIL,
  UPDATE_NOTIFICATION_TO_READ_SUCCESS,
  UPDATE_NOTIFICATION_TO_READ_REQUEST,
  UPDATE_NOTIFICATION_TO_READ_FAIL,
} from 'redux/constants/notificationConstants';
import {
  updateNotificationService,
  getAllNotifications,
} from 'services/api/notification-services';
import { getConversationListByID } from './chatActions';

export const handleNewFallbackReceiveNotificationAction =
  () => async (dispatch) => {
    try {
      dispatch({
        type: GET_FALLBACK_NOTIFICATIONS_REQUEST,
      });
      const { data } = await getAllNotifications();
      dispatch({
        type: GET_FALLBACK_NOTIFICATIONS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_FALLBACK_NOTIFICATIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const UpdateNotificationByIDAction =
  (information) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_NOTIFICATION_TO_READ_REQUEST,
      });

      const { data } = await updateNotificationService(information);

      dispatch({
        type: UPDATE_NOTIFICATION_TO_READ_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_NOTIFICATION_TO_READ_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const handleIncomingNotificationAction =
  (remoteMessage) => async (dispatch) => {
    if (!remoteMessage) {
      return null;
    }
    const { data } = remoteMessage.data;

    try {
      dispatch({
        type: GET_NOTIFICATIONS_REQUEST,
      });
      console.log(data);
      if (data.type === 'fallback_received') {
        dispatch(handleNewFallbackReceiveNotificationAction());
      }
      if (data.type === 'new_message_received') {
        // dispatch(getConversationListByID(data.conversation_id));
      }

      dispatch({
        type: GET_NOTIFICATIONS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_NOTIFICATIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
