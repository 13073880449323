import { getChatByIDService } from 'services/api/chat-services';
import { sendImageService } from 'services/api/chat-services';
import {
  sendMessageService,
  getAllChatsService,
  removeChatService,
} from 'services/api/chat-services';
import {
  USER_CONVERSATION_BY_ID_REQUEST,
  USER_CONVERSATION_BY_ID_FAIL,
  USER_CONVERSATION_BY_ID_SUCCESS,
  SEND_MESSAGE_BY_EMAIL_REQUEST,
  SEND_MESSAGE_BY_EMAIL_SUCCESS,
  SEND_MESSAGE_BY_EMAIL_FAIL,
  GET_ALL_CONVERSATIONS_REQUEST,
  GET_ALL_CONVERSATIONS_SUCCESS,
  GET_ALL_CONVERSATIONS_FAIL,
  SEND_IMAGE_REQUEST,
  SEND_IMAGE_SUCCESS,
  SEND_IMAGE_FAIL,
  REMOVE_CHAT_SUCCESS,
  REMOVE_CHAT_REQUEST,
  REMOVE_CHAT_FAIL,
} from './../constants/chatConstants';

export const getAllConversationList = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_CONVERSATIONS_REQUEST });

    const { data } = await getAllChatsService();

    dispatch({
      type: GET_ALL_CONVERSATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CONVERSATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getConversationListByID = (information) => async (dispatch) => {
  try {
    dispatch({ type: USER_CONVERSATION_BY_ID_REQUEST });

    const { data } = await getChatByIDService(information);

    dispatch({
      type: USER_CONVERSATION_BY_ID_SUCCESS,
      payload: data,
      getID: information.conversation_id,
    });
  } catch (error) {
    dispatch({
      type: USER_CONVERSATION_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const sendMessageFromChat = (information) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_MESSAGE_BY_EMAIL_REQUEST,
    });

    const { data } = await sendMessageService(information);

    dispatch({
      type: SEND_MESSAGE_BY_EMAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEND_MESSAGE_BY_EMAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const sendImageFromChat = (information) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_IMAGE_REQUEST,
    });

    const { data } = await sendImageService(information);

    dispatch({
      type: SEND_IMAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEND_IMAGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeChat = (information) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_CHAT_REQUEST,
    });

    const { data } = await removeChatService(information);

    dispatch({
      type: REMOVE_CHAT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_CHAT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
